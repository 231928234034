import jQuery from 'jquery/dist/jquery';
import * as bootstrap from 'bootstrap'
import Utils from '../../../../utils'
import DefaultController from "../../../defaultController";

export default class TeamsCalendarList extends DefaultController {
    private changed = false
    private currentDate = new Date();
    private mode: any = null
    private users = []
    private absentOnly = false
    private sickOnly = false
    getEntityData(elem: any) {
        return {}
    }

    async init() {
        await super.init()
        if (!this.mode) {
            this.mode = ((document.querySelector("#calendar_row") as HTMLDivElement).getAttribute("data-mode") as string)
        }
        this.setInputValue();
    }

    async updateContent() {
        if (!this.mode) {
            this.mode = ((document.querySelector("#calendar_row") as HTMLDivElement).getAttribute("data-mode") as string)
        }
        await Utils.showLoader()
        const content = await Utils.entity.request(`/api/v1/calendar/content`, "POST", {
            date: this.currentDate.getTime(),
            users: this.users,
            mode: this.mode,
            absent_only: this.absentOnly,
            sick_only: this.sickOnly,
        });
        if (content.status === 200) {
            (document.querySelector("#calendar_row") as HTMLDivElement).innerHTML = content.content
        }
        await Utils.hideLoader()
    }

    bindListeners() {
        (document.querySelector("#calendar_prev") as HTMLElement).addEventListener("click", async (e) => {
            this.changed = true
            e.preventDefault()
            if (this.mode === "weekly") {
                const d = new Date(this.currentDate);
                d.setDate(d.getDate() - 7);
                this.currentDate = d
            } else if(this.mode === "daily") {
                const d = new Date(this.currentDate);
                d.setDate(d.getDate() - 1)
                this.currentDate = d
            } else {
                const d = new Date(this.currentDate);
                d.setMonth(d.getMonth() - 1)
                this.currentDate = d
            }
            await this.updateContent()
            this.setInputValue()
        });

        (document.querySelector("#calendar_next") as HTMLButtonElement).addEventListener("click", async (e) => {
            this.changed = true
            e.preventDefault()
            if (this.mode === "weekly") {
                const d = new Date(this.currentDate);
                d.setDate(d.getDate() + 7);
                this.currentDate = d
            } else if(this.mode === "daily") {
                const d = new Date(this.currentDate);
                d.setDate(d.getDate() + 1)
                this.currentDate = d
            } else {
                const d = new Date(this.currentDate);
                d.setMonth(d.getMonth() + 1)
                this.currentDate = d
            }
            await this.updateContent()
            this.setInputValue()
        });
        (document.querySelector("#calendar_absent_only") as HTMLButtonElement).addEventListener("click", async (e) => {
            e.preventDefault();
            if (this.absentOnly) {
                (document.querySelector("#calendar_absent_only") as HTMLButtonElement).innerHTML = Utils.translate("calendar.only_show_absent")
                this.absentOnly = false;

                (document.querySelector("#calendar_sick_only") as HTMLButtonElement).innerHTML = Utils.translate("calendar.only_show_sick");
                this.sickOnly = false;

            } else {
                (document.querySelector("#calendar_absent_only") as HTMLButtonElement).innerHTML = Utils.translate("calendar.show_all");
                (document.querySelector("#calendar_sick_only") as HTMLButtonElement).innerHTML = Utils.translate("calendar.only_show_sick");
                this.sickOnly = false;
                this.absentOnly = true;
            }
            await this.updateContent()
            this.setInputValue()
        });
        (document.querySelector("#calendar_sick_only") as HTMLButtonElement).addEventListener("click", async (e) => {
            e.preventDefault();
            if (this.sickOnly) {
                (document.querySelector("#calendar_sick_only") as HTMLButtonElement).innerHTML = Utils.translate("calendar.only_show_sick")
                this.sickOnly = false;

                (document.querySelector("#calendar_absent_only") as HTMLButtonElement).innerHTML = Utils.translate("calendar.only_show_absent");
                this.absentOnly = false;
            } else {
                (document.querySelector("#calendar_sick_only") as HTMLButtonElement).innerHTML = Utils.translate("calendar.show_all")
                this.sickOnly = true;
                (document.querySelector("#calendar_absent_only") as HTMLButtonElement).innerHTML = Utils.translate("calendar.only_show_absent");
                this.absentOnly = false;
            }
            await this.updateContent()
            this.setInputValue()
        });

        (document.querySelector("#calendar_daily") as HTMLButtonElement).addEventListener("click", async (e) => {
            e.preventDefault();
            const v = (document.querySelector("#calendar_times_date") as HTMLInputElement).value
            if (this.changed) {
                this.currentDate = this.parseDate(v)
            } else {
                this.currentDate = new Date()
            }
            document.querySelectorAll(".calendar_btn").forEach((e) => {
                e.classList.remove("btn-primary")
                e.classList.add("btn-secondary")
            });
            (document.querySelector("#calendar_daily") as HTMLButtonElement).classList.add("btn-primary");
            (document.querySelector("#calendar_daily") as HTMLButtonElement).classList.remove("btn-secondary");
            this.mode = "daily";
            await this.updateContent()
            this.setInputValue()
        });

        (document.querySelector("#calendar_weekly") as HTMLButtonElement).addEventListener("click", async (e) => {
            e.preventDefault();
            const v = (document.querySelector("#calendar_times_date") as HTMLInputElement).value;
            if (this.changed) {
                this.currentDate = this.parseDate(v)
            } else {
                this.currentDate = new Date()
            };

            document.querySelectorAll(".calendar_btn").forEach((e) => {
                e.classList.remove("btn-primary")
                e.classList.add("btn-secondary")
            });

            (document.querySelector("#calendar_weekly") as HTMLButtonElement).classList.add("btn-primary");
            (document.querySelector("#calendar_weekly") as HTMLButtonElement).classList.remove("btn-secondary");
            this.mode = "weekly";
            await this.updateContent()
            this.setInputValue()
        });

        (document.querySelector("#calendar_monthly") as HTMLButtonElement).addEventListener("click", async (e) => {
            e.preventDefault();
            const v = (document.querySelector("#calendar_times_date") as HTMLInputElement).value;
            if (this.changed) {
                this.currentDate = this.parseDate(v)
            } else {
                this.currentDate = new Date()
            }
            document.querySelectorAll(".calendar_btn").forEach((e) => {
                e.classList.remove("btn-primary")
                e.classList.add("btn-secondary")
            });
            (document.querySelector("#calendar_monthly") as HTMLButtonElement).classList.add("btn-primary");
            (document.querySelector("#calendar_monthly") as HTMLButtonElement).classList.remove("btn-secondary")
            this.mode = "monthly";
            await this.updateContent()
            this.setInputValue()
        })


        jQuery('#calendar_user_select').on('select2:select', async (e: any) => {
            const data = e.params.data;
            console.log(data);
            // @ts-ignore
            this.users.push(data.id)
            await this.updateContent()
        });
        jQuery('#calendar_user_select').on('select2:unselect', async (e: any) => {
            const data = e.params.data;
            this.users = this.users.filter(u => u !== data.id)
            await this.updateContent()
        });
    }

    setInputValue() {
        if (this.mode === "monthly") {
            (document.querySelector("#calendar_times_date") as HTMLInputElement).setAttribute("type", "month")
            let month: any = this.currentDate.getMonth()+1
            if (month <= 9) {
                month = `0${month}`
            }
            (document.querySelector("#calendar_times_date") as HTMLInputElement).setAttribute("type", "month");
            (document.querySelector("#calendar_times_date") as HTMLInputElement).value = `${this.currentDate.getFullYear()}-${month}`
        } else if(this.mode === "daily") {
            let month: any = this.currentDate.getMonth()+1
            if (month <= 9) {
                month = `0${month}`
            }
            let day: any = this.currentDate.getDate()
            if (day === 0) {
                day = 1
            }
            if (day <= 9) {
                day = `0${day}`
            }
            (document.querySelector("#calendar_times_date") as HTMLInputElement).setAttribute("type", "date");
            (document.querySelector("#calendar_times_date") as HTMLInputElement).value = `${this.currentDate.getFullYear()}-${month}-${day}`
        } else {
            (document.querySelector("#calendar_times_date") as HTMLInputElement).setAttribute("type", "week");
            (document.querySelector("#calendar_times_date") as HTMLInputElement).value = `${this.currentDate.getFullYear()}-W${this.getWeekNumber(this.currentDate)}`
        }
        (document.querySelector(".calendar-title") as HTMLElement).innerHTML = this.currentDate.toLocaleString(navigator.language, { month: "long", year: "numeric" });
    }
    getWeekNumber(date: Date) {
        const d = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
        const dayNum = d.getUTCDay() || 7;
        d.setUTCDate(d.getUTCDate() + 4 - dayNum);
        const yearStart = new Date(Date.UTC(d.getUTCFullYear(),0,1));
        // @ts-ignore
        let num: any = Math.ceil((((d - yearStart) / 86400000) + 1)/7)
        if (num <= 9) {
            num = `0${num}`
        }
        return num;
    }

    parseDate(v: any) {
        if (this.mode === "monthly") {
            return this.parseMonth(v)
        } else if(this.mode === "daily") {
            return this.parseDay(v)
        } else {
            return this.parseWeek(v)
        }
    }

    parseDay(v: any) {
        return new Date(v.split("-")[0], parseInt(v.split("-")[1])-1, v.split("-")[2])
    }

    parseMonth(v: any) {
        return new Date(v.split("-")[0], parseInt(v.split("-")[1])-1, 10)
    }

    parseWeek(v: string) {
        const w = v.split("-")[1].replace("W", "")
        const y = v.split("-")[0]
        // @ts-ignore
        const d = (1 + (w - 1) * 7);
        // @ts-ignore
        return new Date(y, 0, d);
    }
}